import { useState, useCallback, useMemo, useEffect } from 'react';
import { useMutation } from '@apollo/client';
import { useCartContext } from '@/lib/context';
import { UPDATE_QUANTITY_MUTATION } from '@/components/ProductListing/Product/api/product.gql';
import { useIsTaxIncludedInPrice } from '@/hooks/useIsTaxIncludedInPrice';
import { flattenProduct } from '@/components/ProductListing/Product/hooks/useProduct';
import { deriveErrorMessage } from '@/lib/Apollo/utils/deriveErrorMessage';
import type { IUseItem, IUseItemProps } from '../types';
import type { ConfigurableCartItem } from '@/types/product';

export const useItem = ({
    configurable_options,
    configurableThumbnailSource,
    handleRemoveItem,
    id,
    prices,
    product,
    quantity,
    setIsUpdatingQuantity,
}: IUseItemProps): IUseItem => {
    const { getPriceWithOrWithoutTax } = useIsTaxIncludedInPrice();
    const [isDeleting, setIsDeleting] = useState(false);
    const [{ cartId }] = useCartContext();

    const [updateItemQuantity, { called: updateItemCalled, error: updateItemError, loading: updateItemLoading }] =
        useMutation(UPDATE_QUANTITY_MUTATION, {
            context: {
                skipErrorToasts: true,
            },
        });

    const removeItem = useCallback(() => {
        setIsDeleting(true);
        handleRemoveItem(id);
    }, [handleRemoveItem, id]);

    const handleUpdateItemQuantity = useCallback(
        async (quantity: number) => {
            try {
                await updateItemQuantity({
                    variables: {
                        cartId,
                        itemId: id,
                        quantity,
                    },
                });
            } catch (error) {
                if (process.env.NODE_ENV !== 'production') {
                    console.error(error);
                }
            }
        },
        [cartId, id, updateItemQuantity],
    );

    const flatProduct = flattenProduct(
        { configurable_options, prices, product, quantity } as ConfigurableCartItem,
        configurableThumbnailSource,
        getPriceWithOrWithoutTax,
    );

    const derivedErrorMessage = useMemo(() => deriveErrorMessage([updateItemError]), [updateItemError]);
    const isUpdating = updateItemCalled && updateItemLoading;

    useEffect(() => {
        setIsUpdatingQuantity(isUpdating);
    }, [isUpdating, setIsUpdatingQuantity]);

    return {
        errorMessage: derivedErrorMessage,
        flatProduct,
        handleUpdateItemQuantity,
        isDeleting,
        isUpdating,
        removeItem,
    };
};
