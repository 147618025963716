import { useCallback } from 'react';

import { useCartContext } from '@/lib/context';
import { useDropdown } from '@/hooks/useDropdown';
import { useResourceUrl } from '@/hooks/useResourceUrl';
import { urls } from '@/constants';
import type { IUseCartTrigger } from '@/components/Header/types';
import { useHhRouter } from '@/utils/useHhRouter';

export const useCartTrigger = (): IUseCartTrigger => {
    const [{ cartContent }] = useCartContext();
    const {
        elementRef: miniCartRef,
        expanded: isMiniCartOpen,
        setExpanded: setIsMiniCartOpen,
        triggerRef: miniCartTriggerRef,
    } = useDropdown();
    const router = useHhRouter();
    const resourceUrl = useResourceUrl();

    const itemCount = cartContent?.cart.total_quantity || 0;
    const subtotal = cartContent?.cart.prices.grand_total || 0;
    const handleTriggerClick = useCallback(() => {
        // Open the mini cart.
        setIsMiniCartOpen((isOpen) => !isOpen);
    }, [setIsMiniCartOpen]);

    const handleLinkClick = useCallback(() => {
        // Send the user to the cart page.
        router.push(resourceUrl(urls.cart));
    }, [resourceUrl, router]);

    return {
        handleLinkClick,
        handleTriggerClick,
        isMiniCartOpen,
        itemCount,
        miniCartRef,
        miniCartTriggerRef,
        setIsMiniCartOpen,
        subtotal,
    };
};
