'use client';

import React, { useRef, useMemo, useCallback } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import { useQuery } from '@apollo/experimental-nextjs-app-support/ssr';
import { GET_RECAPTCHA_CONFIG } from './getReCaptchaConfig.gql';
import useUserAgent from '@/hooks/useUserAgent';

/**
 * @param {Object} props
 * @param {'customer_create'|'customer_login'|'customer_forgot_password'|'newsletter'} props.reCaptchaConfigField
 * @param {Function} props.onSubmit
 * @param {'inline'|'bottomright'|'bottomleft'} [props.badge='inline']
 * @param {'compact'|'normal'|'invisible'} [props.size='invisible']
 * @param {'light'|'dark'} [props.theme='light']
 * @return {Object}
 */
export const useReCaptcha = ({
    badge = 'inline',
    onSubmit,
    reCaptchaConfigField,
    size = 'invisible',
    theme = 'light',
}) => {
    const reCaptchaRef = useRef();
    const { isBot } = useUserAgent();

    const { data, loading: isReCaptchaLoading } = useQuery(GET_RECAPTCHA_CONFIG, {
        fetchPolicy: 'no-cache',
        skip: isBot,
    });

    const reCaptchaComponent = useMemo(() => {
        if (isReCaptchaLoading || !reCaptchaConfigField) return null;

        const { type, website_key } = data?.reCaptchaConfig?.[reCaptchaConfigField] || {};

        return website_key && type === 'INVISIBLE' ? (
            <ReCAPTCHA ref={reCaptchaRef} sitekey={website_key} badge={badge} size={size} theme={theme} />
        ) : null;
    }, [data, isReCaptchaLoading, reCaptchaConfigField, reCaptchaRef]);

    const handleSubmitWithReCaptcha = useCallback(
        async (args) => {
            // When captcha isn't active in admin, call the original submit event
            if (!reCaptchaComponent || !reCaptchaRef.current) {
                return onSubmit(args);
            }

            const token = await reCaptchaRef.current.executeAsync();

            // ref: https://github.com/dozoisch/react-google-recaptcha/issues/191#issuecomment-715635172
            reCaptchaRef.current.reset();

            return onSubmit({
                ...args,
                context: {
                    headers: {
                        'X-ReCaptcha': token,
                    },
                },
            });
        },
        [reCaptchaComponent, onSubmit, reCaptchaRef],
    );

    return {
        handleSubmitWithReCaptcha,
        isReCaptchaLoading,
        reCaptchaComponent,
    };
};

export default useReCaptcha;
