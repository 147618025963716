import { gql } from '@apollo/client';
import { MiniCartFragment } from 'src/components/MiniCart/api/miniCartFragments.gql';
import { AvailableShippingMethodsCartFragment } from 'src/talons/CartPage/ShippingMethods/shippingMethodsFragments.gql';

export const REMOVE_ITEM_MUTATION = gql`
    mutation removeItem($cartId: String!, $itemId: Int!) {
        removeItemFromCart(input: { cart_id: $cartId, cart_item_id: $itemId }) @connection(key: "removeItemFromCart") {
            cart {
                id
                ...MiniCartFragment
                ...AvailableShippingMethodsCartFragment
            }
        }
    }
    ${MiniCartFragment}
    ${AvailableShippingMethodsCartFragment}
`;

export const UPDATE_QUANTITY_MUTATION = gql`
    mutation updateItemQuantity($cartId: String!, $itemId: Int!, $quantity: Float!) {
        updateCartItems(input: { cart_id: $cartId, cart_items: [{ cart_item_id: $itemId, quantity: $quantity }] })
            @connection(key: "updateCartItems") {
            cart {
                id
                ...MiniCartFragment
                ...AvailableShippingMethodsCartFragment
            }
        }
    }
    ${MiniCartFragment}
    ${AvailableShippingMethodsCartFragment}
`;

export default {
    removeItemMutation: REMOVE_ITEM_MUTATION,
    updateItemQuantityMutation: UPDATE_QUANTITY_MUTATION,
};
