import { useCallback, useEffect, useState } from 'react';
import throttle from 'lodash.throttle';
import { useUpdateMiniCartData } from '@/components/MiniCart';
import { MINI_CART_QUERY } from '@/components/MiniCart/api/miniCart.gql';
import { GENERAL_HEADER_REF, TOP_HEADER_REF } from '@/constants/refIds';
import type { IUseHeader } from '../types';

export const useHeader = (): IUseHeader => {
    useUpdateMiniCartData({ operation: MINI_CART_QUERY });

    const [isHeaderVisible, setIsHeaderVisible] = useState(false);
    const [prevScrollY, setPrevScrollY] = useState(0);
    const [prevBlockHeight, setPrevBlockHeight] = useState(0);

    const controlDirection = useCallback(() => {
        if (window.scrollY === prevScrollY) return;

        if (!window.scrollY) {
            setIsHeaderVisible(false);
        }

        if (window.scrollY <= prevScrollY) {
            setIsHeaderVisible(true);
        } else if (window.scrollY >= prevScrollY) {
            setIsHeaderVisible(false);
        }
        setPrevScrollY(window.scrollY);
    }, [prevScrollY]);

    useEffect(() => {
        const topHeader = window.document.getElementById(TOP_HEADER_REF);
        const generalHeader = window.document.getElementById(GENERAL_HEADER_REF);

        const generalHeaderHeight = generalHeader?.offsetHeight;

        if (generalHeaderHeight) {
            document.documentElement.style.setProperty('--header-general-height', `${generalHeaderHeight}px`);
        }

        const updateTopHeaderHeight = () => {
            const topHeaderHeight = topHeader?.offsetHeight;

            if (topHeaderHeight) {
                if (topHeaderHeight > prevBlockHeight) {
                    setPrevScrollY((prev) => prev + (topHeaderHeight - prevBlockHeight));
                } else if (topHeaderHeight < prevBlockHeight) {
                    setPrevScrollY((prev) => prev - (prevBlockHeight - topHeaderHeight));
                }

                document.documentElement.style.setProperty('--header-mobile-height', `${topHeaderHeight}px`);
                setPrevBlockHeight(topHeaderHeight);
            }
        };

        const observer = new ResizeObserver(() => {
            updateTopHeaderHeight();
        });

        if (topHeader) {
            observer.observe(topHeader);
        }

        updateTopHeaderHeight();

        return () => {
            if (topHeader) observer.unobserve(topHeader);
            observer.disconnect();
        };
    }, [prevBlockHeight]);

    useEffect(() => {
        const throttled = throttle(controlDirection, 500);
        window.addEventListener('scroll', throttled);

        return () => {
            window.removeEventListener('scroll', throttled);
        };
    }, [controlDirection]);

    return {
        isHeaderVisible,
    };
};
